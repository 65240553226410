/* Core Grid CSS */
//@use "@ag-grid-community/styles" as ag;
//
//
//// Choose alpine over default quartz
//@include ag.grid-styles((
//  theme: quartz
//));

//@import 'primeng-custom-theme';
@import "agGrid-custom-theme";
@import 'primeicons/primeicons.css';

:root {
  --heading-font-size: 22px;
  --heading-line-height: 28px;
  --tress: 1180px;
  --cp-primary-color: #7987FF;
  --cp-border-color: #eaecf0;
  --p-ripple-background: rgba(255, 255, 255, 0.5);
  //--p-togglebutton-checked-background: var(--cp-primary-color);
  //--p-form-field-hover-border-color: var(--cp-primary-color);
  //--p-select-focus-border-color: var(--cp-primary-color);
  //--p-togglebutton-checked-color: #fff;


  --p-button-primary-background: var(--cp-primary-color);
  --p-button-primary-border-color: var(--cp-primary-color);
  --p-button-primary-hover-background: #8c95dc;
  --p-button-primary-hover-border-color: #8c95dc;
  --p-button-primary-active-background: var(--p-button-primary-background);
  --p-button-primary-active-border-color: var(--p-button-primary-hover-border-color);
  --p-button-padding-x: 16px;
  --p-button-padding-y: 9px;
  --p-button-label-font-weight: 600;
  --p-button-border-radius: 6px;


  --p-inputtext-filled-background: #fff;
  --p-button-secondary-background: #fff;
  --p-button-secondary-color: #344054;
  --p-button-secondary-border-color: var(--cp-primary-color);
  //--p-button-secondary-color: var(--cp-primary-color);
  --p-multiselect-border-color: var(--cp-border-color);
  --p-autocomplete-border-color: var(--cp-border-color);


  --p-select-border-radius: 6px;
  --p-select-padding-x: 12px;
  --p-select-padding-y: 7px;
  --p-select-border-color: var(--cp-border-color);

  --p-inputtext-disabled-background: #fff;
  --p-inputtext-hover-border-color: var(--cp-border-color);
  --p-inputtext-focus-border-color: var(--cp-border-color);
  --p-inputtext-padding-x: 12px;
  --p-inputtext-padding-y: 7px;
  --p-inputtext-border-color: var(--cp-border-color);

  --p-checkbox-width: 22px;
  --p-checkbox-height: 22px;
  //--p-checkbox-background: var(--cp-primary-color);

  --p-checkbox-checked-background: var(--cp-primary-color);
  --p-checkbox-checked-border-color: var(--cp-primary-color);
  --p-checkbox-checked-hover-border-color: var(--cp-primary-color);
  --p-checkbox-checked-hover-background: var(--cp-primary-color);
    //--p-togglebutton-checked-color: red !important;


  --p-badge-primary-background: var(--cp-primary-color);


  --p-togglebutton-checked-background: var(--cp-primary-color);
  --p-togglebutton-checked-color: #fff;
  --p-togglebutton-background: #fff;
  --p-togglebutton-checked-border-color: var(--cp-primary-color);
  --p-togglebutton-border-color: var(--cp-border-color);
  --p-togglebutton-padding: 7px 12px;


  --p-textarea-border-radius: 6px;


  --p-datepicker-dropdown-background: var(--cp-primary-color);
  --p-datepicker-dropdown-hover-background: var(--cp-primary-color);
  --p-datepicker-dropdown-active-background: var(--cp-primary-color);
  --p-datepicker-dropdown-hover-color: #fff;
  --p-datepicker-dropdown-active-color: #fff;

  --p-datepicker-dropdown-color: #fff;
  --p-datepicker-dropdown-border-color: var(--cp-primary-color);
  --p-datepicker-date-width: 31px; // 38
  --p-datepicker-date-height: 31px;

  --p-primary-color: var(--cp-primary-color);

  --p-chip-padding-y: 10px;
  --p-chip-padding-x: 8px;
  --p-chip-border-radius: 8px;


  --p-popover-arrow-offset: 318px;

  .p-dialog-mask.p-overlay-mask.p-overlay-mask-enter {
    z-index: 8000 !important;
  }

  .p-button.p-component.p-button-outlined {
    color: #344054;
  }

  .p-button.p-button-rounded.p-button-text.p-dialog-close-button {
    padding: 9px;
  }

  .p-popover {
    z-index: 7000 !important;
  }

  .p-datepicker-panel {
    z-index: 9999 !important;
  }

  p-button {
    font-size: 14px;
    line-height: 20px;
  }

  .p-dialog-content {
    height: 100%;
  }

  .p-confirmdialog {
    .p-dialog-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;

      p-button {
        button {
          width: 100%;
        }
      }
    }
  }
}

.p-confirmdialog {
  width: 430px;
}

.ag-charts-series-area {
  border-bottom: 1px solid #cecece !important;
}

.p-select-label.p-placeholder {
  font-size: 14px;
}

p-selectitem {
  .p-select-option {
    //font-size: 14px;
  }
}

.p-select-overlay {
  width: 100%;
}

@media (max-width: 1180px) {
  :root {
    --heading-font-size: 19px;
    --heading-line-height: 22px;
  }
}

.overview-cell-link__wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.overview-cell-link {

  color: var(--cp-primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  cursor: pointer;
  line-height: 1;

  &:hover {
    span {
      text-decoration: underline;
    }
    i {
      text-decoration: none;
    }
  }

  //span {
  //  max-width: 150px;
  //  text-overflow: ellipsis;
  //  overflow: hidden;
  //  white-space: nowrap;
  //}

  i {
    margin-left: 4px;
    font-size: 14px;
  }
}



//https://www.dev-notes.ru/articles/css/a-more-modern-css-reset/
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font: inherit;
}

/* Make sure textarea without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

body {
  font-family: "Inter", sans-serif;
  background: #FBFBFF;
}


main {
  padding: 49px 24px 24px;
  position: relative;
  z-index: 1;
}

.drop-down-admin-platform__date {
  font-size: 13px;
  line-height: 1;
  font-style: italic;
}

.kpi-value-arrow {
  display: inline-block;
  position: relative;
  //display: flex;
  //align-items: center;
  //grid-column-gap: 5px;
  //justify-content: center;

  svg {
    top: 0;
    position: absolute;
    right: -6px;
    transform: translateX(100%);
    bottom: 0;
    margin: auto;
  }
}

/* Custom transition */

.main {
  view-transition-name: main;
}

@keyframes rotate-out {
  to {
    //transform: rotate(90deg);
    transform: translateY(40px)
  }
}
@keyframes rotate-in {
  from {
    //transform: rotate(-90deg);
    transform: translateY(-40px)
  }
}


.qwer-fix .lead-table__filters {
  input {
    height: 40px;
  }
}

.ag-cell-value[col-id="lead_status"] {}

.case_value_cell {
  display: flex;
  flex-direction: column;
}

.ag-cell-value[col-id="case_value"], .ag-cell-value[col-id="deal_size"] {
  display: flex;
  align-items: center;
  cursor: pointer;
}


ag-grid-angular {
  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #7987FF;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #7987FF;
  }

}

span[lead-status-color], span[risk-status-color] {
  display: inline-block;
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: white;
}
span[lead-status-color="Mark as Disqualified"] {
  background-color: #E06661;
}
span[lead-status-color="Mark as Qualified"] {
  background-color: #54B471;
}
span[lead-status-color="Not a Fit"] {
  background-color: #E2E3E5;
}
span[lead-status-color="Opportunity"] {
  background-color: #5C85D4;
}
span[lead-status-color="Closed / Won"] {
  background-color: #2C6B2F;
}
span[lead-status-color="Closed / Lost"] {
  background-color: #8B0000;
}


.ag-horizontal-left-spacer::-webkit-scrollbar {
  opacity: 0;
}

.ag-horizontal-left-spacer::-webkit-scrollbar-track {
  opacity: 0;
}

.ag-horizontal-left-spacer::-webkit-scrollbar-thumb {
  opacity: 0;
}

/* Для Firefox */
.ag-horizontal-left-spacer {
  scrollbar-color: transparent transparent;
}



.overview-forms-overlay {
  max-width: 80vw;
  width: 100%;
  right: 0;
  margin: 10px auto;

  &:after, &:before {
    display: none !important;
  }
}

.overview-forms-bottom-actions {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 8px;

  .rows-counter {
    font-size: 14px;
    color: #667085;
  }

  div {
    display: flex;
    grid-column-gap: 8px;
  }
}

.overview-google-ads-cnv-act-overlay {
  max-width: 700px;
  width: 100%;
  right: 0;
  //margin: 10px auto;
  max-height: 340px;
  overflow-y: auto;

  //ul {
  //  li {
  //    margin-bottom: 10px;
  //    //border-bottom: 1px solid #667085;
  //    padding-bottom: 10px;
  //
  //    &:last-child {
  //      border-bottom: none;
  //    }
  //  }
  //}

  &:after, &:before {
    display: none !important;
  }
}

//.row_is_blocked {
//  background-color: red !important;
//}

.row_is_blocked {
  //pointer-events: none;
}
.ag-pinned-left-cols-container {
  .row_is_blocked {

    &:after {
      content: '';
      position: absolute;
      cursor: wait;
      pointer-events: auto;
      bottom: 0;
      left: 0;
      right: 0;
      will-change: opacity;
      //width: 100%;
      width: 100vw;
      //height: 4px;
      opacity: 0.1;
      height: 100%;
      background: linear-gradient(to right, #FF9894, #7986FF, #FECF9E, #FF9894);
      background-size: 200% auto;
      animation: gradient 2s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      z-index: 10000;
    }
  }
}

.ag-pinned-right-cols-container {
  .row_is_blocked {

    &:after {
      content: '';
      position: absolute;
      cursor: wait;
      pointer-events: auto;
      bottom: 0;
      //left: 0;
      right: 0;
      will-change: opacity;
      //width: 100%;
      width: 100vw;
      //height: 4px;
      opacity: 0.1;
      height: 100%;
      background: linear-gradient(to right, #FF9894, #7986FF, #FECF9E, #FF9894);
      background-size: 200% auto;
      animation: gradient 2s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      z-index: 10000;
    }
  }
}

@keyframes gradient {
  0%   { background-position: 0 0; }
  100% { background-position: -200% 0; }
}
