//@import '@ag-grid-community/styles/ag-grid.css';
//@import '@ag-grid-community/styles/ag-theme-quartz.css';

mai1n {
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet, red);
  //font-weight: 600;
  //font-size: 20px;
  animation: animatedTextGradient .1s linear infinite;
  //background-clip: text;
  //-webkit-text-fill-color: transparent;
  background-size: 200% auto;
}

@keyframes animatedTextGradient {
  33% {
    background-position: -200% center;
  }
  66% {
    background-position: -100% center;
  }
  100% {
    background-position: -200% center;
  }
}

.cp-kpi-grid {
  ag-grid-angular {
    --ag-row-height: 51px !important;
  }
}


ag-grid-angular {
  --ag-font-family: "Inter", sans-serif !important;
  --ag-border-color: #EAECF0 !important;
  --ag-borders: none !important;
  --ag-header-height: 44px !important;
  --ag-row-height: 72px !important;
  --ag-header-background-color: #F4F5FF !important;
  --ag-side-bar-background-color: #F4F5FF !important;
  //--ag-cell-horizontal-border: solid #F4F5FF;
  --ag-wrapper-border-radius: 8px !important;


  .ag-filter-menu .ag-set-filter-list {
    min-width: 290px;
  }

  .ag-tooltip {
    padding: 4px 10px;
  }

  .ag-tooltip.ag-tooltip-animate, {
    transition: opacity 0.2s;
  }

  .ag-header {
    border-bottom: 1px solid #EAECF0;
  }

  .ag-header-row {
    font-weight: 600;
    color: #667085;
  }

  .ag-row-hover {
    &:before {
      content: none !important;
      background-color: red;
    }
  }

  //.ag-cell-last-left-pinned {
  .ag-pinned-left-cols-container {
    //border-right: 1px solid red !important;
    box-shadow: 5px 0 10px rgba(234, 236, 240, 0.75);
    z-index: 1;
  }

  .ag-row.row-auto-data {
    //background-color: #F5F8FE !important;
    background-color: #FFFDF4 !important;
  }

  .row-transparent-outer {
    //background-color: #e8e7e7;
    background-color: #F4F5FF;
  }

  .ag-cell-focus {
    border-color: #7986FF;
  }

  .ag-cell-focus:not(.kpi-editable-cell) {
    border-color: transparent !important;
  }

  .kpi-editable-cell {
    cursor: text;
  }
}

.ag-charts-tooltip {
  box-shadow: none !important;
  z-index: 99999999 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .ag-charts-tooltip-heading {
    //height: 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 8px;
    color: #fff;
  }

  .ag-charts-tooltip-content {
    //padding: 4px;
    //border-bottom-left-radius: 8px;
    //border-bottom-right-radius: 8px;
    padding: 8px;
    gap: 4px;
    grid: none;
  }

  .ag-charts-tooltip-content__bold {
    font-weight: bold;
  }

  .ag-charts-tooltip-content__grid {
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-auto-flow: column;
    grid-row-gap: 4px;
    grid-column-gap: 20px;
  }
}

.ag-pivot-mode-panel {
  display: none;
}

.ag-status-bar {
  //background-color: #F2F4F7;
  background-color: #F4F5FF;
}

.chart-total-separator {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 3px 0;
}

.ag-header-menu-always-show {
  transition: opacity .3s;
  opacity: .5 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.ag-pinned-left-sticky-top {
  > div.ag-row-odd {
    z-index: 1;
  }
}

.ag-row {
  &.row-group-total {
    background-color: #F4F5FF;
    font-weight: bold;
  }
  &.row-group-auto {
    background-color: #fff;
  }
}

.kpi-custom-cell {
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 8px 8px 0;
    border-color: transparent #7986FF transparent transparent;
  }
}

.kpi-grid {

  .ag-header {
    position: sticky;
    top: 82px;
    z-index: 5;
  }

  .ag-root-wrapper,
  .ag-root-wrapper-body,
  .ag-root {
    display: unset;
  }

  .ag-row-selected::before {
    background-color: #fff;
  }
}
ag-grid-angular.dashboard-grid {
  .ag-root-wrapper.ag-layout-auto-height {
    min-height: 500px !important;
  }
}

///////
.ag-center-cols-viewport {
  //padding-left: 30px;
}
.ag-center-cols-viewport {
  .ag-row {
    //margin-left: 30px;
  }
}

.chart-tooltip__color-box {
  display: flex;
  width: 15px;
  height: 15px;
  margin-right: 6px;
}


.chart-tooltip__item {
  display: flex;
  align-items: center;
  padding-left: 5px;
  text-transform: capitalize;
}

app-kpi {
  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-cell {
    text-align: center;
  }
}

// sticky header styles
.grid-wrapper {
  //will-change: padding;
  //width: 100vw;
  //width: calc(100vw - 24px);
  //width: 90%;
  padding-left: 24px;
  padding-right: 24px;
  //transition: padding 0.1s linear;

  &.ag-sticky {
    position: relative;
    &:before {
      content: '';
      position: fixed;
      height: 97px;
      background-color: #f4f5ff;
      width: 100%;
      left: 0;
      right: 0;
      top: 30px;
      border-bottom: 1px solid #eaecf0;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }


    .ag-header {
      position: fixed;
      top: 82px;
      z-index: 5;
      left: 24px;
      right: 24px;
      //width: calc(100vw - 24px);
      width: calc(100vw - 63px);
      //box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      //width: 90%;

      //left: 24px;
      //right: 24px;
    }
    //padding-left: 0;
    //padding-right: 0;
  }
}

.dashboard {
  .grid-wrapper {
    &.ag-sticky {
      .ag-header {
        left: 48px;
        right: 48px;
        width: calc(100vw - 110px);
      }
    }
  }
}

app-grid-leads {
  .ag-cell-inline-editing {
    background-color: #fff !important;
    box-shadow: none !important;
    outline: none;
    padding: 0 15px;

    .p-dropdown.p-focus, input {
      box-shadow: none;
    }
  }
}
